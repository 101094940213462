.css-3g4ihc-MuiPaper-root-MuiCard-root {
    padding: 0 !important;
}

.css-o4b71y-MuiAccordionSummary-content {
    margin: 0 !important;
}

.react-tags {
    padding: 20px 0;
}

.ReactTags__tags  {
    display: flex;
    align-items: center;
    gap: 20px;
}

.ReactTags__selected {
    display: flex;
    gap: 10px;
}

.ReactTags__tag {
    background-color: #F5F5F5;
    border-radius: 4px;
    padding: 5px;
}

.ReactTags__remove {
    border: none;
    background: none;
    cursor: pointer;
}

.ReactTags__tagInputField {
    padding: 7px;
    border-radius: 4px;
    border: 1px solid #000000;
}

/* HTML: <div class="loader"></div> */
.loader {
    width: 50px;
    aspect-ratio: 1;
    display: grid;
    border: 4px solid #0000;
    border-radius: 50%;
    border-right-color: #1877F2;
    animation: l15 1s infinite linear;
}
.loader::before,
.loader::after {
    content: "";
    grid-area: 1/1;
    margin: 2px;
    border: inherit;
    border-radius: 50%;
    animation: l15 2s infinite;
}
.loader::after {
    margin: 8px;
    animation-duration: 3s;
}
@keyframes l15{
    100%{transform: rotate(1turn)}
}